<template>
  <div>
    <!--begin::User-->
    <div>
          <h3 class="card-label">
            {{ $t('inventory_monitoring.inventory_monitoring') }}
            <span class="text-muted pt-2 font-size-sm d-block"></span>
          </h3>
      <div class="row">
        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('inventory_monitoring.inventory') }}<span class="text-danger">*</span></label>
                <div class="input-group">
                  <multiselect v-model="data.inventory"
                               :placeholder="$t('inventory_monitoring.inventory')"
                               label="name"
                               track-by="id"
                               :options="inventories"
                               :multiple="false"
                               :class="validation && validation.inventory_id ? 'is-invalid' : ''"
                               :taggable="true"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false">
                  </multiselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/settings/inventories/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>
                <span v-if="validation && validation.inventory_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.inventory_id[0] }}
                            </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('inventory_monitoring.code') }}</label>
                <input type="text" v-model="data.code" class="form-control" :class="validation && validation.code ? 'is-invalid' : ''" :placeholder="$t('inventory_monitoring.code')"/>
                <span v-if="validation && validation.code" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.code[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('inventory_monitoring.date') }}</label>
                <input type="date" v-model="data.monitor_date" class="form-control" :class="validation && validation.monitor_date ? 'is-invalid' : ''" :placeholder="$t('inventory_monitoring.date')"/>
                <span v-if="validation && validation.monitor_date" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.monitor_date[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('inventory_monitoring.time') }}</label>
                <input type="time" v-model="data.monitor_time" class="form-control" :class="validation && validation.monitor_time ? 'is-invalid' : ''" :placeholder="$t('inventory_monitoring.time')"/>
                <span v-if="validation && validation.monitor_time" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.monitor_time[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('status') }}</label>
                <select name="" id="status" v-model="data.status" class="custom-select" :class="validation && validation.status ? 'is-invalid' : ''">
                  <option v-for="row in status_list" :value="row.id" :key="row.id">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.status" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.status[0] }}
                            </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-lg-12 pt-8">
          <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
            <h6 class="mt-2">{{ $t('inventory_monitoring.products') }}</h6>
            <div>
              <button type="button" class="btn btn-primary ml-1 mr-1" @click="addToRepeater">{{ $t('add_more') }}</button>
              <button type="button" class="btn btn-primary ml-1 mr-1" @click="getItemsForInventory">{{ $t('bring_all_items') }}</button>
            </div>
          </div>
          <div class="form-group">
            <div class="table-responsive bg-white">
              <table class="table table-row-bordered table-custom-padding" @keyup.alt.enter="addToRepeater" @keyup.alt.46="deleteFromRepeater(0)">
                <thead>
                <tr>
                  <th width="250px">{{ $t('items.item_details') }}</th>
                  <th width="300px">
                    <div class="d-flex justify-content-between">
                      <span>{{ $t('inventory_monitoring.product') }}</span>
                      <a href="javascript:;" class="btn btn-primary btn-sm p-1" @click="showItemModal">
                        <b-icon icon="plus"></b-icon>
                      </a>
                    </div>
                  </th>
                  <th>{{ $t('inventory_monitoring.unit') }}</th>
                  <th>{{ $t('inventory_monitoring.expected_quantity') }}</th>
                  <th>{{ $t('inventory_monitoring.actual_quantity') }}</th>
                  <th class="text-center">{{ $t('actions') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(it, index) in data_items" :key="index">
                  <td>
                    <a :id="'popover-target-'+index" href="javascript:;" v-if="it.item" class="d-flex cursor-pointer">
                      <img :src="it.item.image_url" style="width: 70px; height: 70px;" alt="">
                      <div class="pl-2 pr-2 text-dark">
                        <p class="mb-1" v-if="it.item.sku_code">{{ $t('items.sku_code') }}: {{ it.item.sku_code }}</p>
                        <p class="mb-1" v-if="it.item.name">{{ $t('items.name') }}: {{ it.item.name }}</p>
                        <p class="mb-1" v-if="it.item.sale_price">{{ $t('items.sale_price') }}: {{ it.item.sale_price }}</p>
                        <p class="mb-1" v-if="it.item.purchase_price">{{ $t('items.purchase_price') }}: {{ it.item.purchase_price }}</p>
                        <p class="mb-1" v-if="it.item.sum_available_qty">{{ $t('items.sum_available_qty') }}: {{ it.item.sum_available_qty }}</p>
                      </div>
                    </a>
                  </td>
                  <td>
                    <div class="d-flex search-item-group" v-b-tooltip.hover.leftbottom :title="$t('type_at_least_three_letters') + ' ' + $t('inventory_monitoring.product')">
                      <select name="" id="" v-model="it.f_by" class="custom-select" style="width: 100px;">
                        <option value="name" selected>{{ $t('items.name') }}</option>
                        <option value="sku_code">{{ $t('items.sku_code') }}</option>
                      </select>
                      <!--                                                @input="handlingValue(index)"-->
                      <multiselect v-model="it.item"
                                   :placeholder="$t('inventory_transactions.product')"
                                   :label="it.f_by? it.f_by:'name'"
                                   track-by="id"
                                   :options="items"
                                   :multiple="false"
                                   :taggable="false"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false"
                                   :internal-search="false"
                                   @search-change="getItems(it.f_by, $event)">
                      </multiselect>
                    </div>
                  </td>
                  <td>
                    <multiselect v-model="it.unit"
                                 :placeholder="$t('inventory_transactions.unit')"
                                 label="name"
                                 track-by="id"
                                 :options="units"
                                 :multiple="false"
                                 :taggable="true"
                                 :show-labels="false"
                                 :show-no-options="false"
                                 :show-no-results="false">
                    </multiselect>
                  </td>
                  <td>
                    <input type="number" v-model="it.expected_quantity" class="form-control" :placeholder="$t('inventory_monitoring.expected_quantity')"/>
                  </td>
                  <td>
                    <input type="number" v-model="it.actual_quantity" class="form-control" :placeholder="$t('inventory_monitoring.actual_quantity')"/>
                  </td>


                  <td class="text-center">
                    <v-icon class="text-danger" @click="deleteFromRepeater(index)">mdi-delete</v-icon>

                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-5">
        <b-tabs content-class="mt-3" class="nav-custom-link">
          <b-tab :title="$t('notes')">
            <div class="card card-custom">
              <div class="card-body row">
                <div class="col-lg-12 mb-5">
                  <label>{{ $t('inventory_monitoring.notes') }}</label>
                  <textarea type="text" v-model="data.notes" class="form-control" :class="validation && validation.notes ? 'is-invalid' : ''" :placeholder="$t('inventory_monitoring.notes')"></textarea>
                  <span v-if="validation && validation.notes" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.notes[0] }}
                                </span>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>


        <div class="pl-0 pr-0">
          <div class="row">
            <div class="col-lg-6 mt-10">
              <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
            </div>
          </div>
        </div>


      <b-modal ref="modalItem" size="lg" hide-footer :title="$t('items.add_new_item')">
        <item-form @hide-modal="hideItemModal()" @handling-data="getDataAfterCreateNewItem"></item-form>
      </b-modal>
    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import ItemForm from "@/view/content/forms/ItemForm";
import {mapGetters, mapState} from "vuex";

export default {
  name: "from-inventory-monitoring",
  components: {'item-form': ItemForm},
  data() {
    return {
      mainRoute: 'items/inventory-monitorings',
      mainRouteDependency: 'base/dependency',
      inventories: [],
      items: [],
      units: [],

      idEdit: this.$route.params.id ? this.$route.params.id : null,
      data: {
        inventory_id: null,
        inventory: null,
        code: null,
        monitor_date: null,
        monitor_time: null,
        notes: null,
        status: 1,
      },
      isEditing: false,
      validation: null,
      unit_default: null,
      repeater_items: {id: null, item: null, unit: null, actual_quantity: null, expected_quantity: null},
      data_items: [],
      default_unit: [],
      status_list: [],
    };
  },
  computed: {
    ...mapState({user_personal_info: state => state.profile.user_personal_info}),
    ...mapGetters(["currentUserPersonalInfo"]),

  },
  watch: {

    "data.inventory": function (val) {
      if (val){
        this.data.inventory_id = val.id;
      }else {
        this.data.inventory_id = null;
      }
    },

  },
  methods: {

    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      this.data.status = this.data.status ? '1' : '0';
      this.data.inventory_id = this.data.inventory ? this.data.inventory.id : null;
      ApiService.post(this.mainRoute, {
        ...this.data,
        items: this.data_items,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/items/inventory-monitoring');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    update() {
      this.data.status = this.data.status ? '1' : '0';
      this.data.inventory_id = this.data.inventory ? this.data.inventory.id : null;
      ApiService.put(this.mainRoute + '/' + this.idEdit, {
        ...this.data,
        items: this.data_items,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/items/inventory-monitoring');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    getData() {
      ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
        this.isEditing = true;
        this.data.inventory_id = response.data.data.inventory_id;
        this.data.inventory = response.data.data.inventory;
        this.data.code = response.data.data.code;
        this.data.monitor_date = response.data.data.monitor_date;
        this.data.monitor_time = response.data.data.monitor_time;
        this.data.notes = response.data.data.notes;
        this.data.status = response.data.data.status;

        this.data_items = response.data.data.items;
        if (response.data.data.items.length <= 0) {
          this.addToRepeater();
        }
      });
    },

    getItems(f_by = null, filter = null) {
      let _f_by = f_by ? f_by : 'name';
      if (filter && filter.length >= 3 && _f_by)
        ApiService.get(`${this.mainRouteDependency}/itemsBasedStatistics/${this.data.inventory_id}`, {params: {[_f_by]: filter}}).then((response) => {
          this.items = response.data.data;
        });
    },

    getInventories() {
      ApiService.get(this.mainRouteDependency + "/inventories").then((response) => {
        this.inventories = response.data.data;

      });
    },

    async getUnits() {
      await ApiService.get(this.mainRouteDependency + "/units").then((response) => {
        this.units = response.data.data;
      });
    },

    addToRepeater() {
      this.repeater_items.unit = this.default_unit;
      // this.repeater_items.unit = this.default_unit;
      this.data_items.unshift(this.repeater_items);
      this.repeater_items = {id: null, item: null, unit: this.default_unit, actual_quantity: null, expected_quantity: null};
    },
    deleteFromRepeater(index) {
      if (this.data_items.length > 1)
        this.data_items.splice(index, 1);
    },

    getDataAfterCreateNewItem(object) {
      let promise = this.getItems();
      Promise.all([promise]).then(() => {
        if (object) {
          this.items_list[0].item = object;
          this.items_list[0].unit_price = object.purchase_price;
          this.items_list[0].tax = (object.taxes && object.taxes[0] && object.taxes[0].tax) ? object.taxes[0].tax : null;
          this.items_list[0].tax_2 = (object.taxes && object.taxes[0] && object.taxes[0].tax) ? object.taxes[0].tax : null;
        }
      })
    },

    showItemModal() {
      this.$refs['modalItem'].show()
    },
    hideItemModal() {
      this.$refs['modalItem'].hide();
    },
    getItemsForInventory() {
      if (this.data.inventory_id)
      ApiService.get(`${this.mainRouteDependency}/getAllItemsFromInventoryStatistics/${this.data.inventory_id}`).then((response) => {
        this.data_items = [];
        response.data.data.forEach((row)=>{
          this.repeater_items.item = row.item;
          this.repeater_items.expected_quantity = row.stock_on_hand_qty;
          this.addToRepeater();
        });
      });
    },
    // handlingValue(index){
    //     let x = index;
    //console.log(index);
    // this.data_items[index].purchase_price = this.data_items[index].item.purchase_price;
    // },
    getCode() {
      ApiService.get(this.mainRouteDependency + "/code-setting/10").then((response) => {
        this.data.code = response.data.data.code;
      });
    },

    getStatusList() {
      ApiService.get(this.mainRouteDependency + "/inventory_monitoring_status").then((response) => {
        this.status_list = response.data.data;
      });
    },
    defaultDataForUser(){
      ApiService.get(this.mainRouteDependency +"/default_data_for_user").then((response) => {
        this.data.inventory_id = response.data.data.inventory_id;
        this.data.inventory = response.data.data.inventory;

        this.repeater_items.unit = response.data.data.unit;
        this.default_unit = response.data.data.unit;
        if (this.data_items[0]) {
          this.data_items[0].unit = response.data.data.unit;
        }
      });
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.Inventory_monitoring"), route: '/items/inventory-monitoring'}, {title: (this.idEdit ? this.$t('update') : this.$t('create'))}]);
    this.getStatusList();
    // this.getItems();
    this.getInventories();
    this.getUnits();

    if (this.idEdit) {
      this.getData();
    } else {
      this.getCode();
      this.defaultDataForUser();
      if (this.data_items.length <= 0) {
          this.addToRepeater();
      }
      this.data.monitor_date = new Date().toISOString().slice(0, 10);
    }

  },
};
</script>


